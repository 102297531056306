<template>
  <main class="max-w-screen-2xl mx-auto px-4 py-10 sm:py-16 lg:px-16">
    <h1 class="text-3xl font-heading sm:text-4xl md:text-5xl">Il semble que vous soyez perdu</h1>

    <section class="min-h-[60vh] flex flex-col items-center justify-center text-center">
      <h3 class="text-2xl font-display text-gray-700 sm:text-3xl">Remettons nous sur la voie !</h3>
      <p class="mt-2 text-gray-700">
        Essayez de
        <router-link
          :to="{ name: 'Shop' }"
          class="text-primary border-b-2 border-red-400 hover:text-red-700"
        >
          trouver des items
        </router-link>
        à ajouter à votre panier
      </p>
    </section>
  </main>
</template>
